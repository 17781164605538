import { render, staticRenderFns } from "./txffcTop.vue?vue&type=template&id=5e2ca3e7&scoped=true&"
import script from "./txffcTop.vue?vue&type=script&lang=js&"
export * from "./txffcTop.vue?vue&type=script&lang=js&"
import style0 from "./txffcTop.vue?vue&type=style&index=0&id=5e2ca3e7&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5e2ca3e7",
  null
  
)

export default component.exports